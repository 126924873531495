<script setup lang="ts">
const display = useDisplay()
const config = useRuntimeConfig()
const head = useLocaleHead({
  addDirAttribute: true,
  identifierAttribute: 'id',
  addSeoAttributes: true,
})
const { ogImage, favicon, title, description } = useHeaderInformation()

useHead({
  link: [
    { rel: 'icon', href: favicon.value, id: 'favicon' },
  ],
  meta: [
    { property: 'google-adsense-account', content: `${config.public.googleAdsenseId}` },
    { property: 'og:title', content: title.value },
    { property: 'description', content: description.value },
    { property: 'og:description', content: description.value },
    { property: 'og:image', content: `${config.public.baseUrl}/${ogImage.value}` },
    { property: 'twitter:card', content: 'summary_large_image' },
  ],
  script: [
    { src: 'https://securepubads.g.doubleclick.net/tag/js/gpt.js' },
  ],
})
</script>

<template>
  <Html :lang="head.htmlAttrs.lang" :dir="head.htmlAttrs.dir">
    <Head>
      <Title>{{ title }}</Title>
      <template v-for="link in head.link" :key="link.id">
        <Link :id="link.id" :rel="link.rel" :href="link.href" :hreflang="link.hreflang" />
      </template>
      <template v-for="meta in head.meta" :key="meta.id">
        <Meta :id="meta.id" :name="meta.name" :property="meta.property" :content="meta.content" />
      </template>
    </Head>

    <Body>
      <v-app>
        <LayoutAppBar v-if="display.smAndUp.value" />
        <LayoutMobileAppBar v-else />
        <v-main>
          <v-container class="main-container" fluid>
            <slot />
          </v-container>
        </v-main>

        <LayoutFooter />
        <Snackbar :class="!display.mdAndUp.value ? 'mb-16' : null" />
      </v-app>
    </Body>
  </Html>
</template>

<style>
.main-container {
  padding: 24px;
  max-width: 1920px;
}
</style>
