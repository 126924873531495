<script setup lang="ts">
import IconCart from '~icons/mdi/cart'

const userStore = useUserStore()
const cartStore = useCartV2()
const { countryLocalePath } = useCountry()
const config = useRuntimeConfig()
/* const heilandLogoURL = computed(() => new Date().getMonth() + 1 === 6
  ? '/heiland-com_logo_rainbow-shadow.webp'
  : '/heiland_logo_light.svg') */

const logoURL = computed(() => {
  if (process.server)
    return '/logos/heiland_logo_light.svg'
  return location.origin.endsWith('vetmazing.com') ? '/logos/vetmazing_lightred.svg' : '/logos/heiland_logo_light.svg'
})
</script>

<template>
  <v-system-bar window height="64" color="red-400">
    <NuxtLink :to="countryLocalePath('/')" class="ml-3">
      <v-badge
        v-if="config.public.environment !== 'prod'"
        id="environment-badge"
        :content="config.public.environment"
        :color="config.public.environment === 'dev' ? 'heiland-orange' : 'heiland-light-blue'"
      >
        <nuxt-picture v-if="logoURL" :src="logoURL" width="138" height="30" alt="Logo" />
      </v-badge>
      <nuxt-picture v-else-if="logoURL" :src="logoURL" width="138" height="30" alt="Logo" />
    </NuxtLink>
    <v-spacer />

    <template v-if="userStore.isAuthenticated">
      <!-- NuxtLink to="/">
        <v-btn variant="text" color="white" class="font-weight-bold">
          Menu
        </v-btn>
      </NuxtLink -->
      <v-badge
        v-if="cartStore.total"
        id="cart-badge"
        :max="99"
        :content="cartStore.total"
        color="black-800"
        offset-x="5"
        offset-y="8"
      >
        <v-btn
          :to="countryLocalePath('/cart')"
          class="font-weight-bold"
          color="red-400"
          flat
        >
          Cart
          <v-icon
            :icon="IconCart"
            color="white"
            style="opacity: 1;"
            class="ms-0"
          />
        </v-btn>
      </v-badge>
      <v-btn
        v-else
        :to="countryLocalePath('/cart')"
        class="font-weight-bold"
        color="red-400"
        flat
      >
        Cart
        <v-icon
          :icon="IconCart"
          color="white"
          style="opacity: 1;"
          class="ms-0"
        />
      </v-btn>
    </template>
  </v-system-bar>

  <v-app-bar v-if="userStore.isAuthenticated" flat color="red-400">
    <div class="app-bar__search-wrapper" test-id="search-wrapper">
      <LayoutSearch />
    </div>
  </v-app-bar>
</template>

<style lang="scss">
.app-bar__search-wrapper{
  max-width: 720px;
}
</style>
